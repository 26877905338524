import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash';
import Img from 'gatsby-image'
import { DiscussionEmbed } from "disqus-react"

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import Rating from '../components/rating'
import Recommendation from '../components/recommendation'
import Nose from '../components/nose'
import SignupForm from '../components/signupform'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    const disqusConfig = {
      shortname: process.env.GATSBY_DISQUS_NAME,
      config: { identifier: post.slug, title: post.frontmatter.title },
    }
    const image = post.frontmatter.img
          ? post.frontmatter.img.childImageSharp.resize
          : null

    return (
      <DefaultLayout>
        <SEO 
          title={post.frontmatter.title} 
          description={post.excerpt}
          image={image}
          pathname={this.props.location.pathname}
        />
        <article className="article-page">
          <div className="page-content">
            {post.frontmatter.img && (
              <div className="page-cover-image">
                <figure>
                  <Img
                    className="page-image"
                    key={post.frontmatter.img.childImageSharp.fluid.src}
                    fluid={post.frontmatter.img.childImageSharp.fluid}
                  />
                </figure>
              </div>
            )}
            <div className="wrap-content">
              <header className="header-page">
                <h1 className="page-title">{post.frontmatter.title}</h1>
                { post.frontmatter.recommendation &&
                    <Recommendation recommendation={post.frontmatter.recommendation}/>
                }
                { post.frontmatter.nose &&
                    <Nose nose={post.frontmatter.nose}/>
                }
                <small>
                    { post.frontmatter.amount &&
                        <span>{post.frontmatter.amount} grams </span>
                    }
                    { post.frontmatter.price &&
                    <span>for €{(post.frontmatter.price/100).toFixed(2)}</span>
                    }
                    { post.frontmatter.store &&
                    <span>, from <a href={ post.frontmatter.url }>{post.frontmatter.store}</a>. </span>
                    }
                    <div className="page-grindermethod">
                    { post.frontmatter.grinder &&
                        <span>Ground with {post.frontmatter.grinder}, </span>
                    }
                    { post.frontmatter.methods &&
                    <span>Brewed with&nbsp;
                        { post.frontmatter.methods.map(methodlabel => (
                            <span className="methods" key={methodlabel}>{methodlabel}</span>
                        ))}
                    </span>
                    }
                </div>
                </small>
                { /* post.frontmatter.rating &&
                    <Rating score={post.frontmatter.rating}/>
                */}
                <div className="page-date">
                  <span>{post.frontmatter.date}</span>
                </div>
              </header>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className="page-footer">
                <div className="page-tag">
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.map(tag => (
                      <span key={tag}>
                        <Link  className="tag" to={`/tags/${kebabCase(tag)}/`}># {tag}</Link>
                      </span>
                    ))}
                </div>
            </div>
              <div className="page-footer">
                { previous &&
                <div className="page-tag">
                    <Link to={previous.fields.slug}>← previous: "{previous.frontmatter.title}"</Link>
                </div>
                }
                { next &&
                <div className="page-tag">
                    <Link to={next.fields.slug}>next: "{next.frontmatter.title}" →</Link>
                </div>
                }
              </div>
                <div className='signup-box'>
                    <SignupForm/>
                </div>
            </div>
        </div>
        <DiscussionEmbed {...disqusConfig} />
        </article>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY, MMM DD")
        tags
        rating
        nose
        grinder
        methods
        recommendation
        price
        amount
        store
        url
        img {
          childImageSharp {
            fluid(maxWidth: 3720) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`
