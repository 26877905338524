import React from 'react'
import Img from "gatsby-image"
import Link from 'gatsby';
import { kebabCase } from 'lodash';

const Nose = ({ nose }) => (
<ul className="tags tags--nose">
    {nose.map(noselabel => (
    <li key={noselabel}>
        <a>
        {noselabel}
        </a>
    </li>
    ))}
</ul>
)

export default Nose
